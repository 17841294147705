<template>
  <div
    class="flix-flex flix-flex-list flix-gap-5"
    style="justify-content: flex-start"
  >
    <div
      class="flix-flex flix-flex-center flix-gap-5"
      style="align-items: center; justify-content: flex-start"
    >
      <div>{{ $t("time.weekdays.fromTo").split("{date}")[0] }}</div>
      <div>
        <timePicker
          :time="data[0]"
          :range="range"
          :callback="function(ret) { data[0] = ret; setCallback(); }"
        />
      </div>
      <div>{{ $t("time.weekdays.fromTo").split("{date}")[1] }}</div>
      <div>
        <timePicker
          :time="data[1]"
          :range="range"
          :callback="function (ret) { data[1] = ret; setCallback(); }"
        />
      </div>
    </div>
    <div v-if="error" class="flix-text-danger flix-html-strong flix-html-small">
      {{ $t("dates.error.time") }}
    </div>
  </div>
</template>
<script>
export default {
  components: {
    timePicker() {
      return import("@/components/default/form/timePicker");
    }
  },
  props: {
    time: Array,
    callback: Function,
    range: {
      type: [Number, String],
      default() {
        return 15;
      }
    }
  },
  data() {
    return {
      data: JSON.parse(JSON.stringify(this.time)),
      error: false
    };
  },
  computed: {},
  mounted() {
    this.checkDate();
  },
  methods: {
    checkDate() {
      if (
        this.data[0].split(":").join("") * 1 <=
        this.data[1].split(":").join("") * 1
      ) {
        this.error = false;
      } else {
        this.error = true;
      }
    },
    setCallback() {
      this.checkDate();
      if (!this.error) {
        this.callback(this.data);
      }
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/includes/timePickerRange.sass'
</style>
